import {Button, Card, Input, Spinner} from "@nextui-org/react";
import {useState} from "react";
import Logo from "../../assets/icons/Logo.png";
import axios from "axios";

const Login = () => {
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [sendData, setSendData] = useState({
        username: "",
        password: ""
    });
    const submitData = () => {
        axios.post("https://team-backend-new.tds.media/auth/", {...sendData}).then(
            res => {
                localStorage.setItem("tdsToken", `${res.data.token}`);
                setIsLoading(true);
                const timeoutId = setTimeout(() => {
                    window.location.reload();
                }, 500);
                return () => clearTimeout(timeoutId);
            }
        ).catch(
            err => {
                setError(err.response.data.non_field_errors[0]);
            }
        )
    }

    if (isLoading) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
            }}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            gap: 40,
            marginTop: -100
        }}>
            <img src={Logo} style={{height: 220}}/>
            <Card style={{
                background: "#121212",
                width: 300,
                maxHeight: 320,
                borderRadius: 12,
                padding: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16
            }}>
                <h2 style={{padding: 0, margin: 0, color: "white", fontSize: 24, fontWeight: 800}}>
                    Вход
                </h2>
                {
                    error && <p style={{color: "crimson", fontSize: 12}}>{error}</p>
                }
                <Input
                    type={"email"}
                    label="Логин"
                    size={"sm"}
                    radius="lg"
                    style={{color: "white"}}
                    className={"input-text-color"}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                    onChange={(event) => {
                        setSendData({...sendData, username: event.target.value});
                        setError("");
                    }}
                    placeholder="Укажите логин team.tds.media"
                />
                <Input
                    type={"password"}
                    label="Пароль"
                    size={"sm"}
                    radius="lg"
                    style={{color: "white"}}
                    className={"input-text-color"}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                    onChange={(event) => {
                        setSendData({...sendData, password: event.target.value});
                        setError("");
                    }}
                    placeholder="Введите пароль team.tds.media"
                />
                <Button style={{
                    background: "#ffbf49",
                    color: "black",
                    fontWeight: 600
                }}
                        onClick={submitData}
                >
                    <p>Войти</p>
                </Button>
            </Card>
            <p style={{fontSize: 10, fontWeight: "bold", color: "white"}}>
                Copyright © 2024 TDS Media. All rights reserved.
            </p>
        </div>
    )
}

export default Login;
