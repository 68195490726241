import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import Login from "./pages/login/Login";
import TikTokApplicationList from "./pages/applicationList/TikTokApplicationList";
import Application from "./pages/application/Application";
import {FillsList} from "./pages/fillsList/FillsList";
import {DetailedFill} from "./pages/detailedFill/DetailedFill";
import NewTikTokApplicationList from "./pages/newApplicationList/NewTikTokApplicationList";

const useRoutes = (isAuthenticated) => {
    if(isAuthenticated){
        return (
            <Routes>
                {/*список заявок*/}
                <Route path="/new_applications/:system" element={<NewTikTokApplicationList/>} />
                <Route path="/all_applications/:system" element={<TikTokApplicationList/>} />
                {/*подробная заявка*/}
                <Route path="/new_applications/:system/:id" element={<Application/>} />
                <Route path="/all_applications/:system/:id" element={<Application/>} />
                {/*подробная заявка*/}
                <Route path="/fills/" element={<FillsList/>} />
                {/*подробная заявка*/}
                <Route path="/fills/:id" element={<DetailedFill/>} />
                <Route path="*" element={<Navigate to="/new_applications/tiktok" />} />
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default useRoutes;
