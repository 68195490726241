import {BrowserRouter as Router} from 'react-router-dom';
import useRoutes from "./routes";
import {useContext} from "react";
import AuthContext from "./context/authContext";
import SideBar from "./components/SideBar";

function App() {
    const {auth} = useContext(AuthContext);
    const routes = useRoutes(auth);
    return (
        <div style={{display:"flex"}}>
            <Router>
                {auth && <SideBar/>}
                <div style={{width:"100%", height:"100vh"}}>
                    {routes}
                </div>
            </Router>
        </div>
    );
}

export default App;

