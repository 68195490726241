import React from 'react';
import Logo from '../assets/icons/Logo.png';
// import {EmployersIcon} from "../assets/icons/EmployersIcon";
import {useNavigate} from "react-router";
import {LogoutIcon} from "../assets/icons/LogoutIcon";
import {EmployersIcon} from "../assets/icons/EmployersIcon";
import {ReportsIcon} from "../assets/icons/ReportsIcon";
// import {LogoutIcon} from "../assets/icons/LogoutIcon";
// import {PromoIcon} from "../assets/icons/PromoIcon";
// import {NewsIcon} from "../assets/icons/NewsIcon";
// import {NotificationsIcon} from "../assets/icons/NotificationsIcon";
// import {StatisticsIcon} from "../assets/icons/StatisticsIcon";
// import {ReportsIcon} from "../assets/icons/ReportsIcon";
// import {ClubIcon} from "../assets/icons/ClubIcon";
// import {UsersIcon} from "../assets/icons/UsersIcon";

const SideBar = () => {
    const history = useNavigate();
    const destinations = [
        {
            href: '/new_applications/tiktok',
            destinationKey: "new_applications",
            label: "TikTok Новые Заявки",
            icon: <ReportsIcon
                color={window.location.pathname.includes("new_applications") ? "#ffbf49" : "white"}/>
        },
        {
            href: '/all_applications/tiktok',
            destinationKey: "all_applications",
            label: "TikTok Заявки",
            icon: <ReportsIcon
                color={window.location.pathname.includes("all_applications") ? "#ffbf49" : "white"}/>
        },
        // {
        //     href: '/fills/tiktok',
        //     destinationKey: "fills",
        //     label: "Пополнения",
        //     icon: <ReportsIcon
        //         color={window.location.pathname.includes("fills") ? "#ffbf49" : "white"}/>
        // },
        // {
        //     href: '/applications/tiktok',
        //     destinationKey: "applications",
        //     label: "TikTok Заявки",
        //     icon: <ReportsIcon
        //         color={window.location.pathname.includes("applications") ? "#ffbf49" : "white"}/>
        // },
        // {
        //     href: '/users',
        //     destinationKey: "users",
        //     label: "Пользователи",
        //     icon: <UsersIcon
        //         color={window.location.pathname.includes("users") ? "rgba(187, 255, 0, 1)" : "white"}/>
        // },
        // {
        //     href: '/club',
        //     destinationKey: "club",
        //     label: "Члены клуба",
        //     icon: <ClubIcon
        //         color={window.location.pathname.includes("club") ? "rgba(187, 255, 0, 1)" : "white"}/>
        // },
        // {
        //     href: '/reports',
        //     destinationKey: "reports",
        //     label: "Отчеты",
        //     icon: <ReportsIcon
        //         color={window.location.pathname.includes("reports") ? "rgba(187, 255, 0, 1)" : "white"}/>
        // },
        // {
        //     href: '/statistics',
        //     destinationKey: "statistics",
        //     label: "Статистика",
        //     icon: <StatisticsIcon
        //         color={window.location.pathname.includes("statistics") ? "rgba(187, 255, 0, 1)" : "white"}/>
        // },
        // {
        //     href: '/notifications',
        //     destinationKey: "notifications",
        //     label: "Уведомления",
        //     icon: <NotificationsIcon
        //         color={window.location.pathname.includes("notifications") ? "rgba(187, 255, 0, 1)" : "white"}/>
        // },
        // {
        //     href: '/news',
        //     destinationKey: "news",
        //     label: "Новости",
        //     icon: <NewsIcon
        //         color={window.location.pathname.includes("news") ? "rgba(187, 255, 0, 1)" : "white"}/>
        // },
        // {
        //     href: '/promo',
        //     destinationKey: "promo",
        //     label: "Промо",
        //     icon: <PromoIcon
        //         color={window.location.pathname.includes("promo") ? "rgba(187, 255, 0, 1)" : "white"}/>
        // }
    ]

    const Logout = () => {
        localStorage.clear();
        window.location.reload();
    }

    return (
        <div style={{
            position: 'relative',
            width: "240px",
            height: "100vh",
            background: "#121212",
            borderTopRightRadius: 12,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 16,
            paddingBottom: 16,
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <div style={{marginRight: 16}}>
                <img src={Logo} style={{height: 50, objectFit: "contain"}}/>
            </div>
            <div style={{marginTop: 40, display: "flex", flexDirection: "column", gap: 8}}>
                {
                    destinations.map((destination, index) => {
                        return (
                            <div key={index} onClick={() => {
                                history(destination.href)
                            }} style={
                                window.location.pathname.includes(destination.destinationKey) ?
                                    {
                                        width: 220,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        gap: 8,
                                        borderRadius: 8,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        background: "#222",
                                        color: "#ffbf49"
                                    } :
                                    {
                                        width: 220,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        gap: 8,
                                        borderRadius: 8,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        background: "transparent",
                                        color: "white"
                                    }
                            }>
                                {destination.icon}
                                <p style={{fontSize: 14, fontWeight: "bold", width: "100%"}}>{destination.label}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{
                marginTop: 120,
                display: "flex",
                width: "100%",
                paddingLeft: 12,
                paddingRight: 12,
                cursor: "pointer",
                position: 'absolute',
                bottom: 50,
                justifyContent: "center",
                marginRight: 30
            }}>
                <div style={{display: "flex", gap: 8}} onClick={Logout}>
                    <LogoutIcon/>
                    <p style={{color: "#FF3131", fontWeight: "bold", fontSize: 14}}>Выйти</p>
                </div>
            </div>
        </div>
    )

}

export default SideBar;
