import {
    Button,
    Card,
    Input, Modal, ModalBody, ModalContent, ModalHeader,
    Spinner,
    Textarea,
} from "@nextui-org/react";
import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import axios from "axios";
import {DownloadIcon} from "../../assets/icons/DownloadIcon";
import {CloseIcon} from "../../assets/icons/CloseIcon";

const Application = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const headers = {'Content-Type': 'multipart/form-data'};
    const [denyModal, setDenyModal] = useState(false);
    const [deny, setDeny] = useState("");
    const [denyError, setDenyError] = useState("");
    const [acceptModal, setAcceptModal] = useState(false);
    const [acceptError, setAcceptError] = useState("");
    const [payBill, setPayBill] = useState(null);
    const [application, setApplication] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState("");
    const [sendingMessage, setSendingMessage] = useState(false);
    const params = useParams();

    useEffect(() => {
        axios.get(`https://team-backend-new.tds.media/fills/tiktok/application/${params.id}/details/`).then(
            res => {
                setIsLoading(false);
                console.log(res.data);

                setApplication(res.data);
            }
        ).catch(
            err => {
                console.log(err.response?.data?.message);
            }
        )
    }, []);

    const denyApplication = () => {
        setSendingMessage(true);
        setDeny("");
        setDenyModal(false);
        axios.post(`https://team-backend-new.tds.media/fills/tiktok/application/${params.id}/deny/`, {deny_text: deny}).then(
            res => {
                setShowResult(true);
                setResult(`Заявка от ${application.organization_type} ${application.name} была отклонена`);
            }
        ).catch(
            err => {
                console.log(err);
                setDenyError(err.response?.data?.message);
            }
        )
    }

    const acceptApplication = () => {
        setSendingMessage(true);
        setAcceptModal(false);
        axios.post(`https://team-backend-new.tds.media/fills/tiktok/application/${params.id}/accept/`, {pay_bill: payBill}, {headers: headers}).then(
            res => {
                setPayBill(null);
                setShowResult(true);
                setResult(application.organization_type === "Физ. лицо" ?
                    `Заявка от ${application.organization_type} ${application.name} была принята` :
                    `Счет на оплату ${application.organization_type} ${application.name} отправлен`
                );
            }
        ).catch(
            err => {
                setAcceptError("");
                setShowResult(true);
                setResult(`${err.response?.data?.message}`);
                setDenyError(err.response?.data?.message);
            }
        )
    }

    const sendData = () => {
        setSendingMessage(true);
        axios.get(`https://team-backend-new.tds.media/fills/tiktok/application/${params.id}/create/`).then(
            res => {
                setShowResult(true);
                setResult(`Заявка от ${application.organization_type} ${application.name} была принята`);
            }
        ).catch(
            err => {
                console.log(err);
                setAcceptError("");
                setDenyError(err.response?.data?.message);
            }
        )
    }

    const notifyUser = () => {
        axios.get(`https://team-backend-new.tds.media/fills/tiktok/application/${params.id}/notify/`).then(
            res => {
                setShowResult(true);
                setResult(`Уведомление ${application.organization_type} ${application.name} отправлено`);
            }
        ).catch(
            err => {
                console.log(err);
                setAcceptError("");
                setDenyError(err.response?.data?.message);
            }
        )
    }

    if (isLoading) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "800px",
                height: "100%"
            }}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div style={{
            color: "white",
            width: "100%",
            height: "100vh",
            overflow: "auto",
            boxSizing: "border-box",
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 16
        }}>
            <Card style={{
                overflow: "auto",
                width: "800px",
                background: "#121212",
                color: "white",
                height: "calc(100% - 24px)",
                padding: 24,
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 20
            }}>
                <h2 style={{fontWeight: "bold"}}>Заявка от {application.organization_type} {application.name}</h2>
                <p style={{fontWeight: "bold", color: "#8B8B8F", fontSize: 14}}><a onClick={() => {
                    history(application.application_status === "Новая заявка" ? '/new_applications/tiktok' : '/all_applications/tiktok')
                }} style={{color: "white", cursor: "pointer"}}>{application.application_status === "Новая заявка" ? 'Новые Заявки TikTok' : 'TikTok Заявки'} </a> / Заявка
                    от {application.organization_type} {application.name}</p>
                <h2 style={{fontWeight: "bold"}}>Основные данные</h2>
                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                    <Input
                        disabled={true}
                        type={"text"}
                        label="Наименование"
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        value={application.name}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                    />
                    <Input
                        disabled={true}
                        type={"text"}
                        label="Отрасль"
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        value={application.industry}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                    <Input
                        disabled={true}
                        type={"text"}
                        label="ФИО контакта"
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        value={application.contact_name}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                    />
                    <Input
                        disabled={true}
                        type={"text"}
                        label="Номер телефона"
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        value={application.phone_number}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                    <Input
                        disabled={true}
                        type={"text"}
                        label="Тип организации"
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        value={application.organization_type}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                    />
                    <Input
                        disabled={true}
                        type={"text"}
                        label="Почта"
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        value={application.email}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                    />
                </div>
                {
                    !application.placement && !application.bin_number && !application.reference ? null :
                        <h2 style={{fontWeight: "bold"}}>Дополнительные данные</h2>
                }
                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                    {
                        application.placement &&
                        <Input
                            disabled={true}
                            type={"text"}
                            label="Рекламируемый сайт / приложение / платформа"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            value={application.placement}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                        />
                    }
                </div>
                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                    {
                        application.bin_number &&
                        <Input
                            disabled={true}
                            type={"text"}
                            label="ИИН / БИН организации"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            value={application.bin_number}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                        />
                    }
                </div>
                <h2 style={{fontWeight: "bold"}}>Cумма первого пополнения</h2>
                <Input
                    disabled={true}
                    type={"text"}
                    size={"sm"}
                    radius="lg"
                    style={{color: "white", fontSize:22, fontWeight:"bold"}}
                    className={"input-text-color"}
                    value={`${application.first_pay_sum.toLocaleString("RU-ru")} ₸`}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                />


                {application.reference &&
                <div style={{display:"flex",}}>
                    <div style={{width: "50%"}}>
                        <Button style={{background: "transparent", color: "white"}}
                                onClick={() => {
                                    // Replace 'your_file_url' with the actual URL of the file you want to download
                                    const fileUrl = `https://team-backend-new.tds.media${application.reference}`;

                                    // Create a hidden anchor element
                                    const link = document.createElement('a');
                                    link.href = fileUrl;
                                    link.target = '_blank'; // Open the link in a new tab
                                    link.download = `Справка_о_регистрации_${application.name}`; // The name to save the file with

                                    // Append the anchor element to the document
                                    document.body.appendChild(link);

                                    // Trigger a click on the anchor element
                                    link.click();

                                    // Remove the anchor element from the document
                                    document.body.removeChild(link);
                                }}
                        >
                            <DownloadIcon/>
                            Скачать свидетельство о регистрации
                        </Button>
                    </div>
                    {application.pay_bill &&
                        <div style={{width: "50%"}}>
                            <Button style={{background: "transparent", color: "white"}}
                                    onClick={() => {
                                        // Replace 'your_file_url' with the actual URL of the file you want to download
                                        const fileUrl = `https://team-backend-new.tds.media${application.pay_bill}`;

                                        // Create a hidden anchor element
                                        const link = document.createElement('a');
                                        link.href = fileUrl;
                                        link.target = '_blank'; // Open the link in a new tab
                                        link.download = `Справка_о_регистрации_${application.name}`; // The name to save the file with

                                        // Append the anchor element to the document
                                        document.body.appendChild(link);

                                        // Trigger a click on the anchor element
                                        link.click();

                                        // Remove the anchor element from the document
                                        document.body.removeChild(link);
                                    }}
                            >
                                <DownloadIcon/>
                                Скачать счет на оплату
                            </Button>
                        </div>}
                </div>}


                {application?.organization_type === "Юр. лицо" && application?.application_status === "Ожидает оплату" && application?.company_type === "ТОО" &&
                <div style={{display:"flex", alignItems:"center", gap:16}}>
                    <Button
                        style={{
                            width: "100%",
                            background:"none",
                            color: "white",
                            marginTop:20,
                            border: "2px solid #ffbf49",
                            fontWeight: 600,
                        }}
                        onClick={notifyUser}
                    >
                        <p>Напомнить об оплате</p>
                    </Button>
                    <Button
                        style={{
                            width: "100%",
                            color: "white",
                            marginTop:20,
                            fontWeight: 600,
                        }}
                        color={"success"}
                        onClick={sendData}
                    >
                        <p>Деньги поступили</p>
                    </Button>
                </div>}

                {
                    application?.application_status === "Отклонено" &&
                    <div style={{width: "100%", display: "flex", gap: 12, flexDirection: "row"}}>
                        <h2 style={{color:"crimson", fontWeight:"bold"}}>Заявка была отклонена</h2>
                    </div>
                }

                {
                    application?.application_status === "Принято" &&
                    <div style={{width: "100%", display: "flex", gap: 12, flexDirection: "row"}}>
                        <h2 style={{color:"greenyellow", fontWeight:"bold"}}>Заявка была принята</h2>
                    </div>
                }
                {!application?.company_type && application?.application_status === "Новая заявка" ?
                <div style={{width: "100%", display: "flex", gap: 12, flexDirection: "row"}}>
                    <Button style={{
                        width: "100%",
                        color: "white",
                        fontWeight: 600,
                    }}
                            color={"danger"}
                            onClick={() => {
                                setDenyModal(true)
                            }}
                    >
                        <p>Отклонить</p>
                    </Button>

                    <Button style={{
                        width: "100%",
                        color: "white",
                        fontWeight: 600
                    }}
                            color={"success"}
                            onClick={() => {
                                setAcceptModal(true)
                            }}
                    >
                        <p>Принять</p>
                    </Button>
                </div> : null}


                {application?.company_type === "ИП" && application?.application_status === "Новая заявка" ?
                    <div style={{width: "100%", display: "flex", gap: 12, flexDirection: "row"}}>
                        <Button style={{
                            width: "100%",
                            color: "white",
                            fontWeight: 600,
                        }}
                                color={"danger"}
                                onClick={() => {
                                    setDenyModal(true)
                                }}
                        >
                            <p>Отклонить</p>
                        </Button>

                        <Button style={{
                            width: "100%",
                            color: "white",
                            fontWeight: 600
                        }}
                                color={"success"}
                                onClick={() => {
                                    setAcceptModal(true)
                                }}
                        >
                            <p>Принять</p>
                        </Button>
                    </div> : null}

                {application?.organization_type === "Юр. лицо" && application?.company_type === "ТОО" && application?.application_status === "Новая заявка" &&
                    <div style={{width: "100%", display: "flex", gap: 12, flexDirection: "row"}}>
                        <Button style={{
                            width: "100%",
                            color: "white",
                            fontWeight: 600,
                        }}
                                color={"danger"}
                                onClick={() => {
                                    setDenyModal(true)
                                }}
                        >
                            <p>Отклонить</p>
                        </Button>

                        <Button style={{
                            width: "100%",
                            background:"none",
                            color: "white",
                            border: "2px solid #ffbf49",
                            fontWeight: 600,
                        }}
                                color={"success"}
                                onClick={() => {
                                    setAcceptModal(true)
                                }}
                        >
                            <p>Отправить счет на оплату</p>
                        </Button>
                    </div>}
            </Card>

            {/*Модалка отмены заявки*/}
            <Modal isOpen={denyModal} style={{background: "#121212", height: 340, width: 410}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Отклонить</h2>
                        <Button onClick={() => {
                            setDenyModal(false)
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        {
                            denyError && <p style={{color: "red"}}>{denyError}</p>
                        }
                        <Textarea
                            type={"email"}
                            label="Опишите причину отклонения"
                            labelPlacement={"outside"}
                            size={"sm"}
                            radius="lg"
                            minRows={14}
                            style={{color: "white"}}
                            className={"input-text-color"}
                            onChange={(e) => {
                               setDeny(e.target.value);
                            }}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                        />
                        <Button style={{
                            color: "white",
                            fontSize: 16,
                            fontWeight: 600
                        }}
                                color={"danger"}
                            onClick={denyApplication}
                        >
                            <p>Отклонить</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/*Модалка принятия заявки*/}
            <Modal isOpen={acceptModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>{application?.organization_type === "Юр. лицо" && application?.company_type === "ТОО" ? 'Отправить' : 'Принять'}</h2>
                        <Button onClick={() => {
                            setAcceptModal(false)
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        {
                            acceptError && <p style={{color: "red"}}>{acceptError}</p>
                        }
                        {application?.organization_type === "Юр. лицо" && application?.company_type === "ТОО" &&
                            <>
                                <p style={{color:"white"}}>Прикрепите счет на оплату</p>
                                <input type={'file'} onChange={(event) => {setPayBill(event.target.files[0])}} accept={"application/pdf"}/>
                            </>
                        }
                        <Button
                            onClick={acceptApplication}
                            style={{
                            color: "white",
                            fontSize: 16,
                            fontWeight: 600,
                            marginBottom: 20,
                        }}
                                color={"success"}
                            // onClick={submitCity}
                        >
                            <p>{application?.organization_type === "Юр. лицо" ? 'Отправить' : 'Принять'}</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/*Модалка статуса заявки*/}
            <Modal isOpen={showResult} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Заявка</h2>
                        {/*<Button onClick={() => {*/}
                        {/*    setAcceptModal(false)*/}
                        {/*}} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>*/}
                    </ModalHeader>
                    <ModalBody>
                        <p style={{color:"white"}}>{result}</p>
                        <Button
                            onClick={() => {history(application.application_status === "Ожидает оплату" ? '/all_applications/tiktok' : '/new_applications/tiktok')}}
                            style={{
                                color: "white",
                                fontSize: 16,
                                fontWeight: 600,
                                marginTop:40,
                                marginBottom:20
                            }}
                            color={"success"}
                        >
                            <p>Вернуться к новым заявкам</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/*Модалка статуса заявки*/}
            <Modal isOpen={sendingMessage} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white", marginBottom:40}}>Отправка данных</h2>
                        {/*<Button onClick={() => {*/}
                        {/*    setAcceptModal(false)*/}
                        {/*}} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>*/}
                    </ModalHeader>
                    <Spinner style={{marginBottom:40}}/>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default Application;
