import {
    Card,
    Divider,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    Input,
    Button,
    TableRow,
    TableCell,
    Tooltip,
    ModalContent,
    ModalHeader,
    ModalBody,
    Modal,
    SelectItem,
    Select, Spinner,
} from "@nextui-org/react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {EditIcon} from "../../assets/icons/EditIcon";
import axios from "axios";
import {CloseIcon} from "../../assets/icons/CloseIcon";

const TikTokApplicationsList = () => {
    const history = useNavigate();
    const [name, setName] = useState('');
    const [applications, setApplications] = useState([]);
    const [filters, setFilters] = useState({
        name: "",
        organization_type: "",
        application_status: "",
        created_at: "",
        start_at: "",
        processed_at: "",
    });
    const [openFilters, setOpenFilters] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [industries, setIndustries] = useState([]);
    const [subIndustries, setSubIndustries] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [statusChoices, setStatusChoices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [openFilters, setOpenFilters] = useState(false);

    const onSubmitFilter = () => {
        setIsLoading(true);
        setShowReset(true);
        setOpenFilters(false);
        axios.post('https://team-backend-new.tds.media/fills/tiktok/application/list/', filters).then(
            res => {
                setIsLoading(false);
                console.log(res.data);
                setApplications(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    const resetFilters = () => {
        setIsLoading(true);
        setShowReset(false);
        setFilters({
            name: "",
            email: "",
            industry: "",
            sub_industry: "",
            organization_type: "",
            application_status: "",
            created_at: ""
        });
        axios.post('https://team-backend-new.tds.media/fills/tiktok/application/list/', {
            name: "",
            email: "",
            industry: "",
            sub_industry: "",
            organization_type: "",
            application_status: "",
            created_at: ""
        }).then(
            res => {
                setIsLoading(false);
                console.log(res.data);
                setApplications(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get('https://team-backend-new.tds.media/fills/tiktok/application/list/').then(
            res => {
                // setPlaces(res.data);
                setIsLoading(false);
                console.log(res.data);
                setApplications(res.data.applications);
                // setSubIndustries(res.data.sub_industries);
                setIndustries(res.data.industries);
                setOrganizations(res.data.organization_types);
                setStatusChoices(res.data.status_choices);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }, []);

    return (
        <>
            <div style={{
                color: "white",
                width: "100%",
                height: "calc(100vh - 12px)",
                boxSizing: "border-box",
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 16
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    background: "black",
                    maxWidth: 1300,
                    height: "100%"
                }}>
                    <Card style={{width: "100%", background: "#121212", padding: 24, marginTop:16, height:"100%"}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 16
                        }}>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 24,
                                alignItems: "center"
                            }}>
                                <div style={{
                                    width:"100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    gap: 4,
                                    alignItems: "center"
                                }}>
                                    <h2 style={{color:"white", fontWeight:"bold"}}>TikTok заявки</h2>
                                    <div style={{display:"flex", gap:16}}>
                                        {
                                            showReset &&
                                            <Button style={{
                                                width:"200px",
                                                height: "44px",
                                                background: "none",
                                                color: "white",
                                                fontWeight: 600
                                            }}
                                                    onClick={resetFilters}
                                            >
                                                <p>Сбросить фильтры</p>
                                            </Button>
                                        }
                                        <Button style={{
                                            width:"200px",
                                            height: "44px",
                                            background: "#ffbf49",
                                            color: "black",
                                            fontWeight: 600
                                        }}
                                                onClick={() => {setOpenFilters(true)}}
                                        >
                                            <p>Фильтры</p>
                                        </Button>
                                    </div>

                                </div>
                            </div>
                            <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                            <div>
                                {
                                    isLoading && <div style={{
                                        width: "100%",
                                        height: "calc(100vh - 250px)",
                                        display: "flex",
                                        alignItems:"flex-start",
                                        justifyContent: "center",
                                    }}>
                                        <Spinner/>
                                    </div>
                                }
                                <>
                                    {
                                        !isLoading && applications?.length === 0 &&
                                            <div style={{
                                                width: "100%",
                                                height: "calc(100vh - 250px)",
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                    вашему запросу ничего не найдено</p>
                                            </div>
                                    }
                                    {
                                        !isLoading && applications?.length > 0 &&
                                        <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                               classNames={{
                                                   base: 'table-wrapper',
                                                   table: "table",
                                                   wrapper: "base-wrapper"
                                               }}
                                        >
                                            <TableHeader>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Имя</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Тип организации</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Cумма пополнения</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Дата обработки</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Дата начала</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Дата создания</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textAlign:"center"
                                                }}>Статус заявки</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textAlign: "center"
                                                }}>Действия</TableColumn>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    applications.map((item, index) => (
                                                        <TableRow>
                                                            <TableCell><p style={{
                                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                margin: 0
                                                            }}>{item.name}</p></TableCell>
                                                            <TableCell><p style={{
                                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                margin: 0
                                                            }}>{item.organization_type}</p></TableCell>
                                                            <TableCell><p style={{
                                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                margin: 0
                                                            }}>{item.first_pay_sum.toLocaleString("RU-ru")} ₸</p></TableCell>
                                                            <TableCell><p style={{
                                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                margin: 0
                                                            }}>{item.processed_at}</p></TableCell>
                                                            <TableCell><p style={{
                                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                margin: 0
                                                            }}>{item.start_at}</p></TableCell>
                                                            <TableCell><p style={{
                                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                margin: 0
                                                            }}>{item.created_at}</p></TableCell>
                                                            <TableCell><p style={{
                                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                margin: 0,
                                                                color:"black",
                                                                textAlign:"center",
                                                                fontWeight:"bold",
                                                                padding: 5,
                                                                borderRadius:6,
                                                                background: item.back_color
                                                            }}>{item.application_status}</p></TableCell>
                                                            <TableCell style={{width: 120}}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    justifyContent: "center"
                                                                }}>
                                                                    <Tooltip content="Подробнее">
                                                                        <Button isIconOnly={true}
                                                                                onClick={() => {history(`/all_applications/tiktok/${item.id}`)}}
                                                                                style={{background: "none"}}>
                                                                            <EditIcon/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    }
                                </>
                            </div>
                        </div>
                        <Modal isOpen={openFilters} style={{background: "#121212"}} hideCloseButton={true}>
                            <ModalContent>
                                <ModalHeader style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingBottom: 0,
                                    paddingRight: 16
                                }}>
                                    <h2 style={{color: "white"}}>Фильтры</h2>
                                    <Button onClick={() => {
                                        setOpenFilters(false);
                                    }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                                </ModalHeader>
                                <ModalBody>
                                    <Input
                                        type={"text"}
                                        label="Имя"
                                        size={"sm"}
                                        radius="lg"
                                        value={filters.name}
                                        style={{color: "white"}}
                                        className={"input-text-color"}
                                        classNames={{
                                            label: "input-text-color",
                                            input: "text-white/90",
                                            innerWrapper: "input-text-color",
                                            inputWrapper: "input-main",
                                        }}
                                        onChange={(event) => {
                                            setFilters({...filters, name: event.target.value});
                                        }}
                                    />
                                    <Select
                                        placeholder={"Физ. лицо / Юр. лицо"}
                                        defaultSelectedKeys={filters.organization_type !== '' &&[filters.organization_type]}
                                        onChange={(items) => {
                                            console.log(items.target.value);
                                            setFilters({...filters, organization_type: items.target.value});
                                            if (!items.target.value || items.target.value === "") {
                                                setFilters({...filters, organization_type: ""});
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {organizations.map((item) => (
                                            <SelectItem key={item.name} value={item.name}>
                                                {item.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                    <Select
                                        placeholder={"Статусы"}
                                        defaultSelectedKeys={filters.application_status !== '' &&[filters.application_status]}
                                        onChange={(items) => {
                                            setFilters({...filters, application_status: items.target.value});
                                            if (!items.target.value || items.target.value === "") {
                                                setFilters({...filters, application_status: ""});
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {statusChoices.map((item) => (
                                            <SelectItem key={item.name} value={item.name}>
                                                {item.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                    <Input
                                        label={"Дата обработки"}
                                        labelPlacement={"outside"}
                                        placeholder={"дата"}
                                        value={filters.processed_at}
                                        type={"date"}
                                        size={"lg"}
                                        radius="lg"
                                        style={{color: "white"}}
                                        className={"input-text-color"}
                                        classNames={{
                                            label: "input-text-color",
                                            input: "text-white/90",
                                            innerWrapper: "input-text-color",
                                            inputWrapper: "input-main",
                                        }}
                                        onChange={(event) => {
                                            setFilters({...filters, processed_at: event.target.value});
                                        }}
                                    />
                                    <Input
                                        label={"Дата начала"}
                                        labelPlacement={"outside"}
                                        placeholder={"дата"}
                                        value={filters.start_at}
                                        type={"date"}
                                        size={"lg"}
                                        radius="lg"
                                        style={{color: "white"}}
                                        className={"input-text-color"}
                                        classNames={{
                                            label: "input-text-color",
                                            input: "text-white/90",
                                            innerWrapper: "input-text-color",
                                            inputWrapper: "input-main",
                                        }}
                                        onChange={(event) => {
                                            setFilters({...filters, start_at: event.target.value});
                                        }}
                                    />
                                    <Input
                                        label={"Дата создания"}
                                        labelPlacement={"outside"}
                                        placeholder={"дата"}
                                        value={filters.created_at}
                                        type={"date"}
                                        size={"lg"}
                                        radius="lg"
                                        style={{color: "white"}}
                                        className={"input-text-color"}
                                        classNames={{
                                            label: "input-text-color",
                                            input: "text-white/90",
                                            innerWrapper: "input-text-color",
                                            inputWrapper: "input-main",
                                        }}
                                        onChange={(event) => {
                                            setFilters({...filters, created_at: event.target.value});
                                        }}
                                    />
                                    <Button style={{
                                        marginBottom:10,
                                        width:"100%",
                                        height: "44px",
                                        background: "#ffbf49",
                                        color: "black",
                                        fontWeight: 600
                                    }}
                                            onClick={onSubmitFilter}
                                    >
                                        <p>Найти</p>
                                    </Button>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default TikTokApplicationsList;
